import Link from 'next/link'
import React, { useCallback } from 'react'
import { useGaEvent } from '../../context/route'

interface LinkExternalProps {
    children: React.ReactNode
    href: string
    className?: string
    onClick?: () => void
    juice?: boolean
}

const LinkExternal = ({
    children,
    href,
    juice,
    ...props
}: LinkExternalProps) => {
    const trackEvent = useGaEvent()

    const handleClick = useCallback(() => {
        trackEvent({
            action: 'clickExternalLink',
            id: href,
        })
    }, [href, trackEvent])

    return (
        <Link href={href}>
            <a
                {...props}
                onClick={handleClick}
                rel={!juice ? 'noopener noreferrer' : undefined}
                target="_blank"
            >
                {children}
            </a>
        </Link>
    )
}

export default LinkExternal
