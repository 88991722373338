import React, { useEffect, useRef, useState } from 'react'

import classnames from 'classnames'
import TetherComponent from 'react-tether'
import { CSSTransition } from 'react-transition-group'

import Trans from './trans'

import '../../../scss/react/components/tooltip.scss'
import '../../../scss/react/components/tether.scss'

const isTouchDevice =
    global.document && !!('ontouchstart' in document.documentElement)

interface TooltipProps {
    className?: string
    children?: React.ReactNode
    label: string
    values?: { [key: string]: string | number }
    duration?: number
    timeout?: number
    delay?: number
    dutch?: boolean
}

const Tooltip = ({
    className,
    children,
    label,
    values,
    duration,
    timeout,
    delay,
    ...props
}: TooltipProps) => {
    const ref = useRef()
    const [tether, setTether] = useState(false)
    const [hover, setHover] = useState(true)

    const inTimeout = useRef<any>()
    const outTimeout = useRef<any>()
    const timeoutTimeout = useRef<any>()

    const clearTimeouts = () => {
        clearTimeout(inTimeout.current)
        clearTimeout(outTimeout.current)
        clearTimeout(timeoutTimeout.current)
    }

    useEffect(() => {
        return () => clearTimeouts()
    }, [])

    const hoverEnd = () => {
        clearTimeouts()
        setHover(false)
        outTimeout.current = setTimeout(() => setTether(false), duration)
    }

    const hoverStart = () => {
        clearTimeouts()
        inTimeout.current = setTimeout(() => {
            setTether(true)
            setHover(true)
        }, delay)

        // prevent tooltip from never disappearing
        if (timeout) {
            timeoutTimeout.current = setTimeout(() => {
                hoverEnd()
            }, timeout)
        }
    }

    if (!tether) {
        return (
            <span
                ref={ref}
                className={classnames('zupr-tooltip-holder', className)}
                onMouseLeave={hoverEnd}
                onMouseEnter={(!isTouchDevice && hoverStart) || null}
            >
                {children}
            </span>
        )
    }

    return (
        <TetherComponent
            ref={ref}
            className="zupr-tooltip-tether"
            renderTarget={(ref) => (
                <span
                    ref={ref}
                    className={classnames('zupr-tooltip-holder', className)}
                    onMouseLeave={hoverEnd}
                    onMouseEnter={(!isTouchDevice && hoverStart) || null}
                >
                    {children}
                </span>
            )}
            renderElement={(ref) => (
                <CSSTransition
                    in={hover}
                    timeout={{
                        appear: duration,
                        enter: duration,
                        exit: duration,
                    }}
                    appear
                    unmountOnExit
                    classNames="tooltip-transition"
                >
                    <span ref={ref} className="zupr-tooltip">
                        <Trans {...props} label={label} values={values} />
                    </span>
                </CSSTransition>
            )}
            // @ts-ignore
            attachment="bottom center"
            targetAttachment="top center"
            offset="16px 0"
            constraints={[
                {
                    to: 'window',
                    pin: true,
                    attachment: 'together',
                },
            ]}
        />
    )
}

Tooltip.defaultProps = {
    delay: 600,
    duration: 2000,
    values: {},
    timeout: 5000,
}

export default Tooltip
