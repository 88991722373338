import { t } from '@zupr/i18n'
import { Field } from '@zupr/types/form'
import classnames from 'classnames'
import React from 'react'

import Tooltip from '../../components/tooltip'
import Trans from '../../components/trans'
import Support from './support'
import Valid from './valid'

import { ReactComponent as ErrorIcon } from '../../../../svg/error.svg'
import { ReactComponent as Question } from '../../../../svg/question.svg'

import '../../../../scss/react/form/row-label.scss'

export const Help = ({ label }) => (
    <Tooltip label={t(label, 'help')} className="tooltip-help">
        <Question />
    </Tooltip>
)

export interface LabelProps {
    error?: string
    label?: string
    help?: string
    support?: string
    field?: Field
    className?: string
    children?: React.ReactNode
    htmlFor?: string
    html?: boolean
    plural?: boolean
    valid?: boolean
    optional?: boolean
}

const Label = ({
    error,
    label,
    help,
    support,
    field,
    className,
    children,
    htmlFor,
    html,
    plural,
    valid,
    optional,
}: LabelProps) => {
    if (!field) {
        return (
            <label
                className={classnames('row-label', className)}
                htmlFor={htmlFor}
            >
                {t(label) || children}
                {optional && <small>{` (${t('optional')})`}</small>}
                {help && <Help label={help} />}
                {support && <Support url={support} />}
            </label>
        )
    }

    return (
        <label
            className={classnames('row-label', className)}
            htmlFor={field.name}
        >
            <span>
                <Trans
                    label={
                        label ||
                        field.label ||
                        (field.name &&
                            `${field.name
                                .charAt(0)
                                .toUpperCase()}${field.name.slice(1)}`)
                    }
                    html={html}
                    plural={plural}
                />
            </span>
            {optional && <small>{` (${t('optional')})`}</small>}
            {(field.required || valid) && !(error || field.error) && (
                <Valid field={field} valid={valid} />
            )}
            {(error || field.error) && <ErrorIcon />}
            {help && <Help label={help} />}
            {field.support && <Support url={field.support} />}
        </label>
    )
}

export default Label
